@import '../../../../assets/layout/_layout-variables';
@import '../../../../assets/shared-styles';

.sidepanel {
  position: fixed;
  height: 100%;
  top: $app-header-height;
  right: 0;
  background-color: white;
  z-index: 10000;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease;

  .sidepanel-content {
    position: relative;
    height: 100%;
  }

  .sidepanel-btn-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
    color: $gray-100;
  }

  .sidepanel-btn-collapse {
    position: absolute;
    top: 12px;
    left: -28px;
    background: white;
    border-radius: 2px;
    width: 30px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -4px 0 3px -1px rgba(0, 0, 0, 0.1);
    color: $gray-400;
}
}
