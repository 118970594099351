.job-and-wbs-table-container {
  table {
    position: relative;
    background-color: white;
    border-collapse: collapse !important;
  }

  table thead {
    tr {
      &:first-child {
        th:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-top: 1px solid #e0e0e0;
          pointer-events: none;
        }

        th {
          top: 0;
        }
      }

      th {
        font-size: 12px;
        font-weight: bold;
        background-color: #f3f3f3;
        z-index: 1;
        border-spacing: 0px;
      }
      th:first-child:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        border-left: 1px solid #e0e0e0;
        pointer-events: none;
      }
      th:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
        pointer-events: none;
      }

      .header-id,
      .header-code {
        text-align: left !important;
      }
    }
  }

  table tfoot {
    .footer-row {
    }
  }

  table tbody {
    .error-row {
      border: 2px solid #ff3b3b !important;
      background-color: #ff00001c !important;
    }

    .year-row td {
      background-color: #f7f9fa;
    }

    .job-row td {
      background-color: #e7eef2;
    }

    tr.wbs-loading td {
      height: 500px;
      text-align: center;
      background-color: #f7f9fa;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
      }
    }

    tr.wbs-empty td {
      height: 250px;
    }

    tr {
      td {
        position: relative;
        font-size: 12px;
      }
      td:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
        pointer-events: none;
      }
      td:first-child:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        border-left: 1px solid #e0e0e0;
        pointer-events: none;
      }
      td:not(.no-right-border):not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          height: 100%;
          border-right: 1px solid #e0e0e0;
          pointer-events: none;
        }
      }

      .hide-cell > * {
        opacity: 0;
        visibility: hidden;
      }
      .wbs-row-id,
      .wbs-row-code,
      .year-row-id,
      .year-row-year {
        text-align: left !important;
      }
    }
  }

  table tfoot tr {
    td {
      position: relative;
      font-size: 12px;
    }
    td:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid #e0e0e0;
      pointer-events: none;
    }
    td:first-child:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      border-left: 1px solid #e0e0e0;
      pointer-events: none;
    }
    td:not(.no-right-border) {
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        border-right: 1px solid #e0e0e0;
        pointer-events: none;
      }
    }

    .no-right-border {
      border-right: none;
    }
    .no-left-border {
      border-left: none;
    }
    .no-border {
      border: none;
    }
  }
}
