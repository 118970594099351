.preallocation-dropdown-select-container {
  position: relative;
  font-size: 14px;

  .input {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #d4d6d8;
    padding: 6px 12px;
  }

  .not-clickable {
    cursor: auto;
  }

  .input-multi-select {
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .selected-option {
      margin-right: 4px;
      padding: 0px 4px;
      background-color: #f2f2f2;
      transition: 150ms;
    }
    .selected-option:hover {
      background-color: #f1e0e0;
    }
  }

  .input-multi-select::-webkit-scrollbar {
    display: none;
  }

  .options-container {
    position: absolute;
    top: 100%;
    z-index: 1000;
  }

  .option-separator {
    height: 1px;
    background-color: #ccc;
    margin: 5px 0;
  }

  .options {
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.21);
    max-height: 250px;
    overflow: scroll;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #d4d6d8;
    z-index: 1000;
  }

  .option {
    padding: 6px 12px;
    display: inline-block;
    min-width: 100%;
  }
  .selected {
    cursor: pointer;
    background-color: #f0f1f3;
    border-left: 3px solid rgb(177, 225, 177);
    transition: 150ms;
  }
  .enabled {
    cursor: pointer;
  }
  .enabled:hover {
    background-color: #e4e5e7 !important;
  }

  .filter-section {
    padding: 4px 4px 0 4px;
    .filter-button {
      font-size: 10px;
      width: 100%;
      text-align: right;
      cursor: pointer;
      padding: 0 !important;
      text-decoration: underline;
    }
  }

  .options[data-popper-placement="bottom-start"] {
    margin-top: 0.5rem !important;
  }

  .options[data-popper-placement="top-start"] {
    margin-bottom: 0.5rem !important;
  }
}
