.calendar-spinner-outer, .calendar-error-outer {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.sticky-header {
  position: sticky;
  background: white;
  top: 60px;
  z-index: 2;
}
