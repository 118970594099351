.resource-legend-item {
  .resource-legend-item-circle {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid white;
    margin-right: 4px;
  }

  .resource-legend-item-value {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #212121;
  }

  .resource-legend-item-label {
    font-size: 12px;
    margin-left: 20px;
    color: #666666;
  }
}
