.resource-cell {
  height: 100%;
  display: flex;
  align-items: stretch;

  .resource-cell-color {
    width: 4px;
    margin: -1px 0;
  }

  .resource-cell-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > *:not(:last-child) {
      border-bottom: 1px solid #E0E0E0;
    }

    .resource-cell-content-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 12px;

      .resource-cell-icons {
        display: flex;
        align-items: center;
        position: relative;

        .resource-cell-icon {
          min-width: 24px;
          min-height: 24px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }

          &:first-child {
            margin-left: 8px;
          }

          & > *:not(:last-child) {
            margin-right: 8px;
          }

          svg {
            height: 11px;
          }
        }
      }
    }

    .resource-cell-content-middle {
      height: 160px;
      max-height: 160px;
      padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .resource-cell-content-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px 12px;
    }
  }
}
