.project-cell {
  background-color: #edf1f3;
  height: 100%;
  display: flex;
  align-items: stretch;

  .project-cell-color {
    width: 4px;
    margin: -1px 0;
  }

  .project-cell-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    min-height: 100px;

    .project-cell-name {
      flex: 1 1;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: .15px;
      margin-right: 13px;
    }

    .project-cell-icon {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }

      svg {
        height: 11px;
      }
    }
  }

  .link-disabled {
    color: unset;
    pointer-events: none;
    cursor: not-allowed;
  }
}
