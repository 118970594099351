.project-slot-cell {
  background-color: #edf1f3;
  height: 100%;

  .project-slot-cell-resources {
    font-size: 12px;
    //border-bottom: 1px solid #E0E0E0;
    padding: 22px 10px;
    text-align: center;
    height: 100%; // TODO Remove when bottom subcells are uncommented
  }

  .project-slot-cell-stats {
    padding: 12px 10px;
    text-align: left;

    & > *:first-child {
      border-right: 1px solid #E0E0E0;
      padding-right: 12px;
    }

    & > *:last-child {
      padding-left: 12px;
    }

    & > div {
      & > b {
        font-size: 11px;
      }

      & > div {
        font-size: 10px;
      }
    }
  }
}
