.btn-group-wrapper {
  .btn {
    width: 94px;
    height: 35px;
    font-size: inherit !important;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 1rem;
  }

  .btn-outline-primary:not(:hover) {
    border-color: #E0E0E0;
  }
}
