.resource-card-header {
  display: flex;
  align-items: center;

  &.calendar-shown {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
  }

  .resource-card-header-tile {
    width: 280px;
    flex: none;
  }

  .resource-card-header-info {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .resource-card-header-info-field {
      flex: 1;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      box-sizing: border-box;

      @media (max-width: 1600px) {
        max-width: 100px;
      }

      h6 {
        font-size: 12px;
        color: #495057;
        margin-bottom: 0;
        line-height: 1;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
