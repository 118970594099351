.square-btn {
  padding: 0.5rem 0.5rem !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  span {
    display: inline-block;
  }

  svg {
    display: block;
  }
}
