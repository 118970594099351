.projects-calendar {
  width: 100%;
  table-layout: fixed;
  word-break: break-word;

  thead {
    tr:first-child {
      color: #000;

      td:first-child {
        width: 28%;

        .date-arrow-outer {
          padding: 12px 16px;
        }
      }

      td:not(:first-child) {
        width: 12%;
        border: 1px solid #E0E0E0;
        text-align: center;
        font-weight: 400;
      }
    }
  }

  tbody {
    tr {
      height: 1px;
      border-top: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
      background-color: #fff;

      & > * {
        border-right: 1px solid #E0E0E0;
        height: inherit;
      }

      td {
        padding: 0;
      }
    }
  }
}
