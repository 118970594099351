.team-slot-cell {
  background-color: #edf1f3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 14px;
  }

  .time-bars {
    width: 100%;
    height: 50px;
    padding: 0 5px;
    display: flex;
    align-items: end;
    justify-content: space-around;

    .bar {
      display: flex;
      border: 1px solid #E0E0E0;
      border-radius: 3px;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      min-height: 10px;
      max-height: 110%;
      white-space: nowrap;
    }

    .chargeable-bar {
      width: 35px;
      background-color: #CBDCEA;
    }

    .unchargeable-bar {
      width: 35px;
      background-color: #D4D6D8;
    }

    .free-bar {
      width: 35px;
      background-color: #FFFFFF;
    }
  }
}
