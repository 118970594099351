.disabled {
  cursor: not-allowed !important;
  background-color: transparent;
  color: #c6c6c6 !important;
}
.disabled:focus {
  border: 1px solid #d4d6d8 !important;
}

.cursor {
  cursor: pointer;
}

.readonly {
  cursor: not-allowed !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}
.readonly:focus {
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}

input.readonly {
  color: black !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}

textarea.readonly {
  color: black !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  background-color: transparent !important;
  border-radius: 0px !important;
}
