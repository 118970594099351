.add-filters {
  display: flex;
  flex-direction: column;

  .add-filters-main {
    flex: 1 1 auto;
    display: flex;
    background-color: #fff;

    .add-filters-tab-container {
      flex: 1 1 auto;

      .RRT__inkbar-wrapper {
        padding-left: 30px;
      }
    }

    .body-tabs-layout {
      margin: 0 !important;
    }

    .add-filters-tab-panel {
      background-color: #fff;
      padding: 15px;
      margin-top: 0;
    }
  }
}
