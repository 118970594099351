.resource-legend {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media screen and (max-width: 1600px) {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 1601px) {
    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }
}
