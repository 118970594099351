.checkbox-container {
  display: block;
  position: relative;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    transition: background-color 250ms;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #eee;
    border: 1px solid transparent;
  }
  .checkmark-disabled {
    opacity: 0.4;
  }

  .label-padding {
    padding-left: 34px;
  }

  &:hover input ~ .checkmark {
    background-color: rgb(212, 214, 216);
  }

  input.checked ~ .checkmark {
    background-color: #01579b;
  }

  input:focus ~ .checkmark {
    border: 1px solid lighten(#01579b, 20%);
  }

  input.checked:focus ~ .checkmark {
    border: 1px solid #d4d6d8;
  }

  &:hover input.checked ~ .checkmark {
    background-color: darken(#01579b, 15%);
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    content: "";
    transition: opacity 200ms;
    position: absolute;
    user-select: none;
    opacity: 0;
    left: 9px;
    top: 6px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input.checked ~ .checkmark:after {
    opacity: 1;
  }
}
