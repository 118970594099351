.allocation-form {
  .allocation-tile {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
  }

  .input-disabled {
    background-color: #F1F4F6;
    color: inherit !important;
  }
}
