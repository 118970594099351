.job-code-cell-grid {
  padding: 2px;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  row-gap: 4px;

  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.react-datepicker-popper {
  z-index: 1000000 !important;
}
