.text-input-container {
  font-size: 14px;

  .label {
    cursor: pointer;
  }

  input {
    width: 100%;
    border: 1px solid #d4d6d8;
    border-radius: 4px;
    padding: 6px 12px;
  }
  input:focus {
    border: 1px solid #646566;
  }
}
