.add-resource-filters {
  width: 340px;
  min-width: 340px;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D4D6D8;

  .add-resource-filters-content {
    // flex: 1 1 auto;
    padding: 15px;

    hr {
      background-color: #D4D6D8;
      margin: 24px 0;
    }

    h6 {
      color: #252525;
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 24px;
    }

    .add-resource-filters-input-sm {
      width: 140px;
    }
  }

  .add-resource-filters-footer {
    padding: 12px 15px;
    border-top: 1px solid #D4D6D8;
  }
}
