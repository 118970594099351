@import "../../../../../assets/shared-styles.scss";

.inline-edit-cell {
  border: none;
  border-bottom: 1px solid #01579b;
  background-color: transparent;

  &__error {
    border-bottom: 1px solid $danger;
  }
}
