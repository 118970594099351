.review-table > * .dx-lookup-search-wrapper {
  padding: 2px !important;
  height: 10% !important;
  min-height: 10px !important;
  height: 40px !important;
}

.review-table > * .dx-texteditor-container {
  min-height: 40px !important;
  height: 40px !important;
}

/* TODO: make class reference more specific */
.review-table > * .dx-texteditor-input-container > .dx-texteditor-input {
  font-size: small !important;
}

.review-table > * .dx-toolbar-item-content > .dx-datagrid-search-panel {
  margin-left: 0px !important;
}

.review-table > * .dx-toolbar-item-content > .MuiInputBase-root,
.dx-toolbar-item-content > .MuiInput-root,
.dx-toolbar-item-content > .MuiInput-underline {
  margin-left: 20px !important;
}

.review-table > * .dx-item-content .dx-toolbar-item-content {
  padding-left: 0% !important;
}

.review-table > * .dx-placeholder:before {
  padding-top: 5% !important;
  padding-bottom: 4% !important;
}

.review-table > * .dx-datagrid-header-panel {
  padding: 0 14px 0px 0px !important;
}
