.resource-calendar {
  width: 100%;
  table-layout: fixed;
  word-break: break-word;

  thead {
    tr:first-child {
      color: #000;

      td {
        width: 16%;
        height: 36px;
        border: 1px solid #E0E0E0;
        text-align: center;
        font-weight: 400;
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;
        height: 266px;
        overflow-y: auto;
        padding: 0;
      }
    }
  }
}
