@import '../../../../assets/layout/_layout-variables';

.opaque-overlay {
  background-color: #1C3954;
  opacity: 0.5;
  z-index: 9999;
  position: fixed;
  left: 0;
  top: $app-header-height;
  height: 100%;
  width: 100%;
  overscroll-behavior: contain;
}
