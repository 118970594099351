.resource-tile {
  .resource-hero {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e1bee7;
  }

  .resource-info {
    .resource-name {
      font-size: 20px;
      font-weight: 500;
      color: #000000de;
      margin: 0;
      margin-bottom: 3px;
    }
    .resource-level {
      font-size: 14px;
      margin: 0;
    }

    .resource-status-label {
      font-weight: bold;
      font-size: 14px;
    }

    .resource-hiring-label {
      @extend .resource-status-label;
      color: #13a10e;
    }

    .resource-resigned-label {
      @extend .resource-status-label;
      color: red;
    }
  }
}
