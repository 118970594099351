.resource-activity {
  width: 100%;
  margin-bottom: 4px;
  background-color: #D4D6D8;
  font-size: 10px;
  padding: 6px;
  display: flex;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .resource-activity-select {
    border: 1px solid #01579B;
    background-color: white;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    flex: none;

    transition: all 0.2s ease;

    &.selected {
      background-color: #01579B;

      &:before {
        content: '';
        display: block;
        width: 4px;
        height: 6px;
        border-bottom: 1.5px solid white;
        border-right: 1.5px solid white;
        margin-top: 1.5px;
        margin-left: 3px;
        transform: rotate(45deg);
      }
    }
  }
}


.resource-activity-tooltip {
  z-index: 1;

  .resource-activity-tooltip-content {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    min-width: 180px;
  }
}
