.text-area-container {
  font-size: 14px;

  .label {
    cursor: pointer;
  }

  textarea {
    border: 1px solid #d4d6d8;
    border-radius: 4px;
    padding: 6px 12px;
  }
  textarea:focus {
    border: 1px solid #646566;
  }
}
