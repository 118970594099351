.base-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: white;
  z-index: 2000;
  box-shadow: 0px 0px 100vw 100vh #00000026;
}
.confirm-modal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}
.confirm-modal-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-modal-actions {
  border-top: 1px solid #ebebeb;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
