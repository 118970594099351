.date-picker-input-container {
  font-size: 14px;

  .label {
    cursor: pointer;
  }

  input {
    width: 100%;
    border: 1px solid #d4d6d8;
    border-radius: 4px;
    padding: 6px 12px;
  }
  input:focus {
    border: 1px solid #646566;
  }
}

.date-picker-input-container-little {
  min-width: 90px;
  input {
    text-align: center;
    padding: 1px;
    width: 90px;
    border: 1px solid #d4d6d8;
    border-radius: 4px;
  }
  input:focus {
    border: 1px solid #646566;
  }
}

.react-datepicker__navigation-icon {
  height: 18px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #01579b !important;
}

.react-datepicker__header {
  background-color: #f9fafb;
}
