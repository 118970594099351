.icon {
  color: inherit;
  > svg {
    height: 1em;
    width: 1em;
    font-size: inherit;
    * {
      opacity: 1;
    }
    path,
    rect {
      fill: currentColor;
      stroke: none;
    }
    line {
      stroke: currentColor;
    }
  }
  &.spin {
    > svg {
      animation-name: spin;
      animation-duration: 750ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  &.md {
    > svg {
      height: 1.5em;
      width: 1.5em;
    }
  }

  &.lg {
    > svg {
      height: 2em;
      width: 2em;
    }
  }

  &.xl {
    > svg {
      height: 3em;
      width: 3em;
    }
  }
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
