@import "../../../../../assets/shared-styles.scss";

.wrapper {
  width: 100%;
  background-color: transparent;

  &__error {
    border-bottom: 1px solid $danger;
  }
}
