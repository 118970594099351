.resource-slot-cell {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;

  &:hover {
    .resource-slot-cell-total {
      left: -14px;
      width: 17px;

      span {
        visibility: visible;
      }
    }
  }

  .resource-slot-cell-total {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 3px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #fff;
    background-color: #5A6167;

    &.total-overflow {
      background-color: #F44336;
    }

    &.resource-slot-cell-total-sm {
      font-size: 8px;

      span {
        padding-bottom: 4px;
      }
    }

    span {
      padding-bottom: 8px;
      visibility: collapse;
      transform: scale(-1);
      writing-mode: vertical-lr;
    }
  }

  .resource-slot-cell-activities {
    max-height: 100%;
    overflow-y: auto;

    &.activities-ml {
      margin-left: 3px;
    }

    .highlighted-activity {
      background-color: #E1BEE7;
    }

    .shadowed-activity {
      opacity: 0.5;
    }
  }
}
