.pin-icon {
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 0.8 !important;
  }

  &.pinned {
    opacity: 1;
  }
}
