.resource-card-body {
  padding: 16px;

  .resource-card-body-heading {
    width: 100%;
    display: flex;
    padding-bottom: 20px;

    & > *:first-child {
      flex: 1 1 auto;

      .date-arrow-outer {
        padding: 12px 16px;
      }
    }
  }

  .add-resource-calendar {
    width: 100%;
    table-layout: fixed;
    word-break: break-word;
  }
}
