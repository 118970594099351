.toggle-btn-wrapper {
  .toggle-btn {
    height: 35px;
    font-size: inherit !important;

    &.btn-secondary {
      color: #495057;
    }

    span {
      display: inline-block;
    }

    svg {
      display: block;
    }
  }
}

