.paginator {
  .pag-icon {
    width: 21px;
    height: 21px;
  }
  .clickable-page {
    width: 21px;
    height: 21px;
    text-align: center;
    border-radius: 50%;
    background-color: rgb(240, 240, 240);
    cursor: pointer;
  }
  .clickable-page:hover {
    background-color: rgb(223, 223, 223);
  }

  .selected {
    background-color: rgb(195, 195, 195);
  }
}
